<template>
  <section id="products">
    <TitleMain
      :textMain="'PRODUCTS'"
      :textSub="'產品系列'"
      :leftDecor="true"
      :rightDecor="true"
      class="section_title txt-center"
    />

    <div class="product_serieses">
      <div
        v-for="(series, idx) in serieses"
        :key="`series_${idx}`"
        class="product_series_wrapper"
      >
        <div
          :style="{ backgroundImage: `url('${series.pic}')` }"
          class="product_series"
          @click="clickhandler(series.name)"
        >
          <div class="thumbnail absolute_cover">
            <div class="hint small txt-white txt-center">
              <LinkMore
                linkText="SEE MORE"
                :fillColor="'#fff'"
              />
            </div>
          </div>
          <div class="frame_bg"></div>
          <div class="product_series_name center_all txt-center txt-white">
            <div class="h6">{{ series.name }}</div>
            <div class="h5">{{ series.name_en }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TitleMain from '@/components/TitleMain.vue';
import LinkMore from '@/components/LinkMore.vue';
import goToProductMixin from '@/mixins/goToProductMixin';

import requestApi from '@/lib/http';
import { formatSerieses } from '@/utils/formatResponse';

export default {
  name: 'Products',
  mixins: [goToProductMixin],
  components: {
    TitleMain,
    LinkMore,
  },
  data() {
    return {
      serieses: [],
    };
  },
  methods: {
    clickhandler(name) {
      let routeData = { name: 'Products' };
      const lv1 = name;
      const lv2 = this.menuListFromVuex
        ?.find((main) => main.name === lv1)?.list?.[0]?.name;

      routeData = this.getProductRouteDate(routeData, lv1, lv2);

      this.$router.push(routeData);
    },
    async getData() {
      const { entries: data } = await requestApi('common.getSerieses', {
        limit: 7, // 首頁排版最多顯示7筆
      });
      this.serieses = data.map(formatSerieses).sort((a, b) => a.sort - b.sort);
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
