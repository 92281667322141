<template>
  <section id="marketing" ref="parallax_bg">
    <div class="map_wrapper">
      <TitleMain
        :textMain="'MARKETING'"
        :textSub="'經銷據點'"
        :leftDecor="false"
        :rightDecor="true"
        class="section_title txt-left"
      />
      <div class="map_box">
        <img class="map_pic" :src="mapPics[swiperActiveIndex]" alt="">
        <Pin class="pin_icon" :class="`map_${swiperActiveIndex}`" fillColor="#D30025" />
      </div>
    </div>
    <div class="swiper_wrapper">
      <swiper
        :options="marketingSwiperOption"
        ref="marketingSwiper"
        class="swiper"
      >
        <swiper-slide
          v-for="(slide, idx) in marketingSwiperSlides"
          :key="`slide_${idx}`"
          class="slide"
        >
          <h3 class="slide_name_en txt-white" v-html="slide.name_en"></h3>
          <h5 class="slide_name_cn txt-white" v-html="slide.name_cn"></h5>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev">
          <PrevArrow fillColor="#fff" />
        </div>
        <div class="swiper-button-next" slot="button-next">
          <NextArrow fillColor="#fff" />
        </div>
      </swiper>
      <div class="swiper_btn">
        <BtnMain
          :text="'SEE MORE'"
          :btnType="2"
          @click.native="clickHandler()"
        />
        <div class="swiper-pagination" slot="pagination"></div>
      </div>
    </div>
  </section>
</template>

<script>
import { locations } from '@/lib/const';
import { parallaxPosition } from '@/lib/public';
import mapPic1 from '@/assets/imgs/index/marketing_map_1.png';
import mapPic2 from '@/assets/imgs/index/marketing_map_2.png';
import mapPic3 from '@/assets/imgs/index/marketing_map_3.png';
import mapPic4 from '@/assets/imgs/index/marketing_map_4.png';
import mapPic5 from '@/assets/imgs/index/marketing_map_5.png';
import mapPic6 from '@/assets/imgs/index/marketing_map_6.png';

import TitleMain from '@/components/TitleMain.vue';
import PrevArrow from '@/components/icon/PrevArrow.vue';
import NextArrow from '@/components/icon/NextArrow.vue';
import BtnMain from '@/components/BtnMain.vue';
import Pin from '@/components/icon/Pin.vue';

export default {
  name: 'Marketing',
  components: {
    TitleMain,
    PrevArrow,
    NextArrow,
    BtnMain,
    Pin,
  },
  data() {
    return {
      mapPic1,
      swiperActiveIndex: 0,
      marketingSwiperOption: {
        slidesPerView: 1,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideNextTransitionEnd: () => {
            this.setSwiperActiveIndex(1);
          },
          slidePrevTransitionEnd: () => {
            this.setSwiperActiveIndex(-1);
          },
        },
      },
      mapPics: [
        mapPic1, mapPic2, mapPic3, mapPic4, mapPic5, mapPic6,
      ],
      marketingSwiperSlides: locations,
    };
  },
  methods: {
    setSwiperActiveIndex(accumulator) {
      const marketingSwiper = this.$refs.marketingSwiper?.swiper;

      if (marketingSwiper) {
        const pageCount = this.marketingSwiperSlides.length;

        if (accumulator > 0) {
          this.swiperActiveIndex = (this.swiperActiveIndex + accumulator) % pageCount;
        } else {
          this.swiperActiveIndex += accumulator;
          if (this.swiperActiveIndex < 0) {
            this.swiperActiveIndex += pageCount;
          }
        }
      }
    },
    clickHandler() {
      this.$router.push({
        name: this.marketingSwiperSlides[this.swiperActiveIndex].to,
        query: {
          series_main: this.marketingSwiperSlides[this.swiperActiveIndex].name,
        },
      });
    },
    scrollHandler() {
      const target = this.$refs.parallax_bg;
      target.style.backgroundPosition = parallaxPosition(target);
    },
  },
  created() {
    window.addEventListener('scroll', this.scrollHandler);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
};
</script>
