<template>
  <section id="news">
    <div class="news_wrapper w1400">
      <div class="title_wrapper">
        <TitleMain
          :textMain="'LATEST NEWS'"
          :textSub="'最新消息'"
          :leftDecor="false"
          :rightDecor="true"
          class="section_title txt-left"
        />
        <LinkMore
          class="txt-red"
          linkText="MORE NEWS"
          fillColor="#D30025"
          @click.native="clickHandler()"
        />
      </div>
      <div class="list_wrapper">
        <ul class="list-main">
          <router-link
            v-for="(article, idx) in newsArticles"
            :key="`article_${idx}`"
            :to="{ name: 'News.Article', params: { uuid: article.uuid } }"
          >
            <div class="list_date txt-main small">{{ article.created }}</div>
            <div class="p">{{ article.title }}</div>
          </router-link>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import TitleMain from '@/components/TitleMain.vue';
import LinkMore from '@/components/LinkMore.vue';

import requestApi from '@/lib/http';
import { formatNews } from '@/utils/formatResponse';

export default {
  name: 'News',
  components: {
    TitleMain,
    LinkMore,
  },
  data() {
    return {
      newsArticles: [],
    };
  },
  methods: {
    async getData() {
      const perPage = 2;

      const { entries: data } = await requestApi('common.getNews', {
        limit: perPage, // 每頁幾筆資料
      });
      this.newsArticles = data.map(formatNews);
    },
    clickHandler() {
      this.$router.push({ name: 'News' });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
