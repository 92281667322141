<template>
  <div class="home">
    <Kv ref="kv" />
    <Products />
    <Marketing />
    <Knowledge />
    <News />
    <Feature />
    <AboutUs />
  </div>
</template>

<script>
import '@/assets/scss/index.scss';
import Kv from '@/views/index/Kv.vue';
import Products from '@/views/index/Products.vue';
import Marketing from '@/views/index/Marketing.vue';
import Knowledge from '@/views/index/Knowledge.vue';
import News from '@/views/index/News.vue';
import Feature from '@/views/index/Feature.vue';
import AboutUs from '@/views/index/AboutUs.vue';

export default {
  name: 'Home',
  components: {
    Kv,
    Products,
    Marketing,
    Knowledge,
    News,
    Feature,
    AboutUs,
  },
  data() {
    return {
      kvHeight: 0,
    };
  },
  methods: {
    scrollHandler() {
      if (window.scrollY > this.kvHeight) {
        this.$store.commit('site/SET_HEADER_FIXED', true);
      } else {
        this.$store.commit('site/SET_HEADER_FIXED', false);
      }
    },
  },
  created() {
    window.addEventListener('scroll', this.scrollHandler);
  },
  mounted() {
    /**
     * Get KV height for scroll method
     */
    this.kvHeight = this.$refs.kv.$el.clientHeight;
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
};
</script>
