<template>
  <section id="knowledge">
    <div class="knowledge_wrapper w1400">
      <div class="bg_decor">
        <img src="../../assets/imgs/index/knowledge_bg.png" alt="">
      </div>
      <div class="knowledge_pic">
        <img src="../../assets/imgs/index/img_1.png" alt="">
      </div>
      <div class="knowledge_content">
        <div class="title_wrapper">
          <TitleMain
            :textMain="'KNOWLEDGE'"
            :textSub="'專業知識'"
            :leftDecor="false"
            :rightDecor="true"
            class="section_title txt-left"
          />
          <LinkMore
            class="txt-red"
            linkText="MORE KNOWLEDGE"
            fillColor="#D30025"
            @click.native="clickHandler()"
          />
        </div>
        <div class="list_wrapper">
          <ul class="list-main">
            <router-link
              :to="{ name: 'Knowledge', query: { idx: 3 } }"
            >
              <div class="p">六協廚刀建議磨刀法 - 使用磨刀石</div>
            </router-link>
            <router-link
              :to="{ name: 'Knowledge', query: { idx: 4 } }"
            >
              <div class="p">六協廚刀建議磨刀法 - 使用磨刀棒</div>
            </router-link>
            <router-link
              :to="{ name: 'Knowledge', query: { idx: 0 } }"
            >
              <div class="p">危害分析和重點管制系統</div>
            </router-link>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TitleMain from '@/components/TitleMain.vue';
import LinkMore from '@/components/LinkMore.vue';

export default {
  name: 'Knowledge',
  components: {
    TitleMain,
    LinkMore,
  },
  methods: {
    clickHandler() {
      this.$router.push({ name: 'Knowledge' });
    },
  },
};
</script>
