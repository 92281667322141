<template>
  <section
    id="kv"
    :style="{ height: `${windowHeight}px` }"
  >
    <div class="kv_bg_img"></div>
    <div class="triangle"></div>

    <div class="kv_slogan txt-white">
      <div class="kv_slogan_sub">
        <h5>專業創新</h5>
        <div class="deco"></div>
        <h5>台灣製造</h5>
      </div>
      <div class="kv_slogan_main">
        <h1>MANUFACTURED<br>IN TAIWAN</h1>
      </div>
    </div>

    <div class="kv_single_product">
      <div
        v-for="(product, idx) in products"
        :key="`product_${idx}`"
        class="product_wrapper"
        :class="[
          swiperActiveIndex === idx ? 'show' : '',
        ]"
      >
        <div class="kv_product_itself">
          <img :src="product.productPic" alt="">
        </div>
        <div class="kv_product_shadow">
          <img :src="product.shadowPic" alt="">
        </div>
      </div>
    </div>

    <div
      class="kv_swiper_all_products"
      :class="{ active: swiperTransitionInit }"
    >
      <swiper
        :options="kvSwiperAllOption"
        ref="kvSwiperAllProducts"
        class="swiper"
      >
        <swiper-slide
          v-for="(slide, idx) in kvSwiperSlides"
          :key="`slide_all_${idx}`"
          class="slide"
          @click.native="routerPushForMobile()"
        >
          <h3 class="slide_name txt-main" v-html="slide.name"></h3>
          <div class="slide_content">
            <h5 class="slide_series">{{ slide.series }}</h5>
            <div class="slide_pic">
              <img :src="slide.pic" alt="">
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev">
          <PrevArrow />
        </div>
        <div class="swiper-button-next" slot="button-next">
          <NextArrow />
        </div>
      </swiper>
      <div class="kv_swiper_btn">
        <BtnMain
          :text="'SEE MORE'"
          :btnType="1"
          @click.native="clickHandler()"
        />
      </div>
    </div>

    <div class="kv_scroll_hint txt-white">
      <div class="kv_scroll_hint_text h6">Scroll</div>
    </div>
  </section>
</template>

<script>
/** 中央單一商品展示圖檔 */
import kvSingleProductPic1 from '@/assets/imgs/index/kv_1_1.png';
import kvSingleShadowPic1 from '@/assets/imgs/index/kv_1_2.png';
import kvSingleProductPic2 from '@/assets/imgs/index/kv_2_1.png';
import kvSingleShadowPic2 from '@/assets/imgs/index/kv_2_2.png';
import kvSingleProductPic3 from '@/assets/imgs/index/kv_3_1.png';
import kvSingleShadowPic3 from '@/assets/imgs/index/kv_3_2.png';
import kvSingleProductPic4 from '@/assets/imgs/index/kv_4_1.png';
import kvSingleShadowPic4 from '@/assets/imgs/index/kv_4_2.png';
import kvSingleProductPic5 from '@/assets/imgs/index/kv_5_1.png';
import kvSingleShadowPic5 from '@/assets/imgs/index/kv_5_2.png';
import kvSingleProductPic6 from '@/assets/imgs/index/kv_6_1.png';
import kvSingleShadowPic6 from '@/assets/imgs/index/kv_6_2.png';

/** 右下Swiper控制圖檔 */
import kvAllProductPic1 from '@/assets/imgs/index/kv_img_1.png';
import kvAllProductPic2 from '@/assets/imgs/index/kv_img_2.png';
import kvAllProductPic3 from '@/assets/imgs/index/kv_img_3.png';
import kvAllProductPic4 from '@/assets/imgs/index/kv_img_4.png';
import kvAllProductPic5 from '@/assets/imgs/index/kv_img_5.png';
import kvAllProductPic6 from '@/assets/imgs/index/kv_img_6.png';

import PrevArrow from '@/components/icon/PrevArrow.vue';
import NextArrow from '@/components/icon/NextArrow.vue';
import BtnMain from '@/components/BtnMain.vue';

import goToProductMixin from '@/mixins/goToProductMixin';

export default {
  name: 'Kv',
  mixins: [goToProductMixin],
  components: {
    PrevArrow,
    NextArrow,
    BtnMain,
  },
  data() {
    return {
      windowHeight: null,
      swiperTransitionInit: false,
      swiperActiveIndex: 0,
      kvSwiperAllOption: {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideNextTransitionStart: () => {
            this.setSwiperActiveIndex(1);
          },
          slidePrevTransitionStart: () => {
            this.setSwiperActiveIndex(-1);
          },
          slideChangeTransitionEnd: () => {
            this.swiperTransitionInit = true;
          },
          transitionStart: () => {
            this.swiperTransitionInit = false;
          },
        },
      },
      products: [
        { productPic: kvSingleProductPic1, shadowPic: kvSingleShadowPic1 },
        { productPic: kvSingleProductPic2, shadowPic: kvSingleShadowPic2 },
        { productPic: kvSingleProductPic3, shadowPic: kvSingleShadowPic3 },
        { productPic: kvSingleProductPic4, shadowPic: kvSingleShadowPic4 },
        { productPic: kvSingleProductPic5, shadowPic: kvSingleShadowPic5 },
        { productPic: kvSingleProductPic6, shadowPic: kvSingleShadowPic6 },
      ],
      kvSwiperSlides: [
        {
          name: 'Western Kitchen<br>Series',
          series: '西廚系列',
          pic: kvAllProductPic1,
          url: 'a',
        },
        {
          name: 'Chinese Style<br>Knives',
          series: '中廚系列',
          pic: kvAllProductPic2,
          url: 'b',
        },
        {
          name: 'Japanese Style<br>Knives',
          series: '日廚系列',
          pic: kvAllProductPic3,
          url: 'c',
        },
        {
          name: 'Processed<br>Series',
          series: '食品/肉品加工系列',
          pic: kvAllProductPic4,
          url: 'd',
        },
        {
          name: 'HACCP<br>Series',
          series: 'HACCP系列',
          pic: kvAllProductPic5,
          url: 'e',
        },
        {
          name: 'Ancillaries<br>Series',
          series: '輔助器具/配件系列',
          pic: kvAllProductPic6,
          url: 'f',
        },
      ],
    };
  },
  methods: {
    setSwiperActiveIndex(accumulator) {
      const kvSwiperAllProducts = this.$refs.kvSwiperAllProducts?.swiper;

      if (kvSwiperAllProducts) {
        const pageCount = this.kvSwiperSlides.length;

        if (accumulator > 0) {
          this.swiperActiveIndex = (this.swiperActiveIndex + accumulator) % pageCount;
        } else {
          this.swiperActiveIndex += accumulator;
          if (this.swiperActiveIndex < 0) {
            this.swiperActiveIndex += pageCount;
          }
        }
      }
    },
    clickHandler() {
      let routeData = { name: 'Products' };
      const lv1 = this.kvSwiperSlides[this.swiperActiveIndex].series;
      const lv2 = this.menuListFromVuex
        ?.find((main) => main.name === lv1)?.list?.[0]?.name;

      routeData = this.getProductRouteDate(routeData, lv1, lv2);

      this.$router.push(routeData);
    },
    routerPushForMobile() {
      if (window.screen.width <= 768) this.clickHandler();
    },
    setHeight() {
      this.windowHeight = window.innerHeight;
    },
  },
  created() {
    window.addEventListener('resize', this.setHeight());
    window.addEventListener('orientationchange', this.setHeight());
  },
  destroyed() {
    window.removeEventListener('resize', this.setHeight());
    window.removeEventListener('orientationchange', this.setHeight());
  },
};
</script>
