<template>
  <section id="feature" ref="parallax_bg">
    <div class="feature_wrapper w1400 txt-center ">
      <div class="feature_list">
        <div
          v-for="(feature, idx) in features"
          :key="`feature_${idx}`"
          class="feature_item txt-white"
        >
          <img class="feature_icon" :src="feature.icon" alt="">
          <div class="feature_title h4">{{ feature.title }}</div>
          <div class="feature_content h6">{{ feature.content }}</div>
        </div>
      </div>

      <BtnMain
        :text="'ALL PRODUCTS'"
        :btnType="2"
        @click.native="clickHandler()"
      />
    </div>
  </section>
</template>

<script>
import featurePic1 from '@/assets/imgs/index/icon_product_1.svg';
import featurePic2 from '@/assets/imgs/index/icon_product_2.svg';
import featurePic3 from '@/assets/imgs/index/icon_product_3.svg';

import BtnMain from '@/components/BtnMain.vue';

import { parallaxPosition } from '@/lib/public';

export default {
  name: 'Feature',
  components: {
    BtnMain,
  },
  data() {
    return {
      features: [
        {
          icon: featurePic1,
          title: '德國進口一體式鋼材',
          content: '耐磨耗、防鏽抗腐蝕等特性，一體式鍛造， 給您絕佳的平衡感，使用更舒適方便',
        },
        {
          icon: featurePic2,
          title: '符合NSF®認證',
          content: '擁有NSF® 國際認證，評估產品的原料安全、淨化性能表現等，可視為無毒產品的標誌',
        },
        {
          icon: featurePic3,
          title: '米其林三星餐廳選用刀',
          content: '獲得國際肯定、使用者極高的評價， 成為專業廚師在調理美食時不可或缺的刀具',
        },
      ],
    };
  },
  methods: {
    clickHandler() {
      this.$router.push({ name: 'Products' });
    },
    scrollHandler() {
      const target = this.$refs.parallax_bg;
      target.style.backgroundPosition = parallaxPosition(target);
    },
  },
  created() {
    window.addEventListener('scroll', this.scrollHandler);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
};
</script>
